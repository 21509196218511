

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "va/delete-comment";

const serviceArguments = []

const serviceUrlArguments = []

const serviceSample = {
  "token": "e49bf1fb6001108a79db5748e5eadaf4a2c14ce6c8e9b7ea",
  "comment_id": 19
};


export function VADeleteComment() {
  return <VADeleteCommentComponent/>;
}


class VADeleteCommentComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Video Analysis - Delete a comment of a video</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to delete a comment of a video.<br/>
                    Only the original author of the comment can delete its own comments.<br/>
                    To delete a comment, specify the <i>comment_id</i> in the json used for the request.<br/>
                    The server always checks if comments are allowed for the video.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    POST request
                  </p>
                  <h4>Response</h4>
        <p>
          A JSON with the field "result". If result == ok, the service completed properly, and the field sharedVideoComments contains the new updated list of comments.
        </p>
        <CodeHighlighter code={
          {
            "result": "ok",
            "token": "e49bf1fb6001108a79db5748e5eadaf4a2c14ce6c8e9b7ea",
            "sharedVideoComments": [
              {
                "id": 19,
                "youcoachUid": 15319,
                "comment": "This is a modified comment",
                "createdOn": "2024-11-19T11:43:43+00:00",
                "updatedOn": "2024-11-19T19:14:48+00:00",
                "createdByPicture": "https://local.youcoach.it/sites/default/files/sites/default/files/pictures/picture-15319-1731348216.png",
                "createdBy": "Arthur Wharton"
              },
              {
                "id": 20,
                "youcoachUid": 15319,
                "comment": "This is another comment",
                "createdOn": "2024-11-19T11:43:58+00:00",
                "updatedOn": "2024-11-19T11:43:58+00:00",
                "createdByPicture": "https://local.youcoach.it/sites/default/files/sites/default/files/pictures/picture-15319-1731348216.png",
                "createdBy": "Arthur Wharton"
              },
              {
                "id": 23,
                "youcoachUid": 15319,
                "comment": "This is a new comment",
                "createdOn": "2024-11-19T19:33:58+00:00",
                "updatedOn": "2024-11-19T19:33:58+00:00",
                "createdByPicture": "https://local.youcoach.it/sites/default/files/sites/default/files/pictures/picture-15319-1731348216.png",
                "createdBy": "Arthur Wharton"
              }
            ]
          }
        } />
        <p>

          If result == error, the field <i>message</i> explains what went wrong.

          <>
            <CodeHighlighter code={{
                "result": "error",
                "message": "An error occurred while removing your comment, please try again"
              }} />
          </>
        </p>

        <p>
          Please note that if the user cannot access the video or the comment, a 401 error is return.
        </p>
                  
            </div>
        );
  }


} 