

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "va/videos";

const serviceArguments = []

const serviceUrlArguments = []

const serviceSample = {}


export function VAListVideos() {
  return <VAListVideosComponent/>;
}


class VAListVideosComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Video Analysis - List User's Videos</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the list videos that have been shared with the current user.
                  </p>
                  <p>
                    Shared videos can be shared at different level. This is store in the field shareType, which can be one of the following:
                  </p>
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                      <tr><th>Share type</th><th>Description</th></tr>
                      <tr><td>public</td><td>Everyone with the link can see the video</td></tr>
                      <tr><td>club</td><td>All members (staff and players) of the club specified in the field shareClub can watch the video</td></tr>
                      <tr><td>season</td><td>All members (staff and players) of the season specified in the field shareSeason can watch the video</td></tr>
                      <tr><td>custom</td><td>Only the users listed in the field "users", who can access shareClub and collaborate with shareSeason can watch the video</td></tr>
                    </tbody>
                  </table>
                  <p>
                    The access control is performed on server side, so videos not visible by the current user are not listed.
                  </p>
                  <p>
                    Videos can have comments and likes, but they can also be disabled. The UI can benefit and should presented accordingly to the fields:
                    <ul>
                      <li>likeCount, dislikeCount, commentsCount</li>
                      <li>commentsEnabled, likesEnabled</li>
                    </ul>
                    To get the list of comments, use the service VA - Get Video
                  </p>
                  <p>
                    The video cover is provided with the field thumbnailSignedUrl.
                  </p>
                  <p>
                    The video link is provided by the field signedUrl and a video should be loaded only when it is meant to play.
                  </p>
                  <p>
                    Tags are provided as a single string, with each tag separated by a new line (\n).
                  </p>
                  <CodeHighlighter code={ [
  {
    "id": 23,
    "name": "Demo_rinominato 222",
    "tags": null,
    "createdOn": "2024-11-13T23:10:06+00:00",
    "updatedOn": "2024-11-13T23:10:06+00:00",
    "updatedBy": "Arthur Wharton",
    "shareType": "club",
    "shareToken": "e49bf1fb6001108a79db5748e5eadaf4a2c14ce6c8e9b7ea",
    "shareSeason": 0,
    "description": null,
    "likeCount": 0,
    "dislikeCount": 0,
    "signedUrl": "https://cloudfront.youcoach.com/test/clubs/2675/presentation_29/export_280.mp4?Expires=4853603406&Signature=hq8cZQ06dXAJF9xCbOCrFFYaf7ci4hz-N6eW85nZE3zczfQxXJygrJplh7BxFk7bt~8jSFesFYr-lq1J~S-sKpXrhXeHPqDutgFB8YM2~egisjj47Ex2ttTyouoMWd13yywZmyW~FzI786fUFe1V4ZoG~kodwYvcf4~t9Qb~vffSAdWD-ULAxgGkMd6S9Dvk09ZHND-1qj11r~FrWm~Sgvu3oTLVvOqnF9VFxbg16TzPSMdt23m6UGnQJDIBwrSUGyWOJ6fAZlmbEzaWH1Gxp1yCbDaMJL5dv1NYLWS9q3ihsQedfqQR8tiOsBmAVIlMFu5wLfkit4P2Y8zNTg~Oew__&Key-Pair-Id=K16TX8JY5DS4IQ",
    "commentsCount": 2,
    "commentsEnabled": true,
    "likesEnabled": true,
    "coverTime": 0.391553,
    "thumbnailSignedUrl": "https://cloudfront.youcoach.com/test/clubs/2675/presentation_29/export_280_thumbnail.jpg?Expires=4853603408&Signature=MLTjbPprV5kR1ELr81WqzrO~mjZscW3lH0uxYgscVtkE9u5mkjCW1Q-VeccsXc8ts9ugAvgAygpm3pjK8byTiz~cUgDl~Q30t1lKgSKW0wo2x71g9UXn7sJ8g8BIRB6PMZHWLkuvT3snO939to6jz-ugz9VPScmEDB5CWFt77TFdaq6WGNtCAP~OyZGZjYg25RvrXyYJwtjSSrdOvvsuP1epVkYQkpKQXz5NXFyNf5FKSwsrPu47ubtNOXTgGHE7Pu4K-tWeIBdFlRBCbYGHAIhI-D0x~ccz-rd6gxdRPbXewvN1ZRb5HyBNQG6SpkJKlOrC341L-NeQEm7590Fmhw__&Key-Pair-Id=K16TX8JY5DS4IQ",
    "viewsCount": 5,
    "createdBy": "Arthur Wharton",
    "createdByUid": 15319,
    "users": [],
    "exportedVideoId": 98,
    "shareClub": 2675,
    "duration": 1,
    "createdByPicture": "https://local.youcoach.it/sites/default/files/sites/default/files/pictures/picture-15319-1731348216.png"
  },
  {
    "id": 24,
    "name": "Demo Marty",
    "tags": null,
    "createdOn": "2024-11-13T23:56:18+00:00",
    "updatedOn": "2024-11-19T12:20:25+00:00",
    "updatedBy": "Arthur Wharton",
    "shareType": "public",
    "shareToken": "e17ceca39c987757a57563b18c6c862b0daca2f074132267",
    "shareSeason": 0,
    "description": null,
    "likeCount": 1,
    "dislikeCount": 0,
    "signedUrl": "https://cloudfront.youcoach.com/test/clubs/2675/presentation_12/export_155.mp4?Expires=4853606178&Signature=anPIT3QxBmMbdLrmgRbTyhSu-HaiMiMk7XUkRsRmbTPOg-1gq-8WdSgw7zFFDzIC4P0EoeLHgWuRnRs12NK4Oe0e58c727Ucx6NH77soDyd9oIp3cBF6vLIPk8pZj4l4TqUCjFpYsT61jZtSZSQTk4P8eNOXmTHq1sxckU9Qe~CTP5M8dIb4uYIxtuUnK3Y4-fOj7qaITM-ral9bB5nOe68bjPqfwEXF6qljURzGgt6JA~uEFI2dw4F7loohVJZf-V1iACp7zYJAODEzugu154tuBS-VRI3Q3I7tu~9Za6-AfX8AevQjQRGrh1FFYkHoO99-Crg-PMs1q8nq~p~ZKQ__&Key-Pair-Id=K16TX8JY5DS4IQ",
    "commentsCount": 2,
    "commentsEnabled": true,
    "likesEnabled": true,
    "coverTime": 9.28442,
    "thumbnailSignedUrl": "https://cloudfront.youcoach.com/test/clubs/2675/presentation_12/export_155_thumbnail.jpg?Expires=4853606179&Signature=BmW-E3pletMfkb118N6OAsZWriFvI34IiVNGZECXlRd~q5f35W0SZimT-KHcUJ-EWwZ75Zxi385O~gMssxBLv7ICAPFz2mrOglgn5gCz44~N2fT~~5lzQ0JKubsL3576BYZyA~d0Lr~bc6Rrytq-JJDtOALAMmkZqb-gWE-lEKK96T4Dn4uRapp07vBKC3VQn5t1f8g9ILjfQtsjx-lGMGMzkXsaeuWY1naVAMy~dXY8SZk3IcGr8HPXiZGyuMvPV4sXKCCd~m7NoKtCdydpQy5eEimMimtTvHOVTc9YmMrgyp1301GsBA1siNBgmPrQcLNY1whpBue7ScAfdTx37w__&Key-Pair-Id=K16TX8JY5DS4IQ",
    "viewsCount": 58,
    "createdBy": "Massimo Ferrero",
    "createdByUid": 15335,
    "users": [],
    "exportedVideoId": 77,
    "shareClub": 2675,
    "duration": 24,
    "createdByPicture": "https://local.youcoach.it/pictures/default-avatar.png"
  },
  {
    "id": 25,
    "name": "Area di rigore",
    "tags": "#Difesa\n#Portiere",
    "createdOn": "2024-11-15T08:32:25+00:00",
    "updatedOn": "2024-11-15T08:32:25+00:00",
    "updatedBy": "Arthur Wharton",
    "shareType": "club",
    "shareToken": "a5c196e5f378b2555dde6b6e12fff5d659e4aebe0df85e9b",
    "shareSeason": 0,
    "description": "Come gestire un attacco pericoloso 3vs3 nell'area piccola",
    "likeCount": 0,
    "dislikeCount": 0,
    "signedUrl": "https://cloudfront.youcoach.com/test/clubs/2675/presentation_21/export_96.mp4?Expires=4853723546&Signature=vhAefAUTzRcPqQZVymVwfr30JFkh5ztgSQUfjtPcLmhSs97h75oYQik-Gx8w3yNTahYgbIlEiQoSD0QVoXMet1-LOFsml6KAv6DAddHxuBIlqDvTfh9UqcinnL7uYRTnIjHMt8kEMqbqIPiFImW5lFtESm~~~Z1bgo3pu8q8Qelc3~9wvv8zqgQunNeDSCGH7fKbVTRZ4KIrCPKDBRd~zShCjJpksfa4ESY-glXnMRZ8ukYYQuYOS-g99vBoHd7W9scRFG0JyJuKn2SS7aXOigOF1AkOIsa1aHE9z5u-E3ZH8oJxVHC9p-~qYJ~IZ1QeIlfjR6YJ3jiQIp6hvcY5Sw__&Key-Pair-Id=K16TX8JY5DS4IQ",
    "commentsCount": 0,
    "commentsEnabled": false,
    "likesEnabled": false,
    "coverTime": 9.121271,
    "thumbnailSignedUrl": "https://cloudfront.youcoach.com/test/clubs/2675/presentation_21/export_96_thumbnail.jpg?Expires=4853723548&Signature=Kvq6rkrHXHZ7cF-Bcx75EP7feihqpijg4qL8W8e~1BDF3vl3EE5O0V2SxT~1ztytmfsA1r5z87zYwf6jtvaQIzHfNeB7HdjMwgYosn9WYu6MPmSrjAPCCXReJtBHe0uKmXt2caS1jFNiKh9Q1U3mQlE8UmgGAR2J~wa6LOp6SEKxqBSPCCwEb77s5vPwTG1Dh5MLf4rJuzQBfc2bd9gWY~lhWPg~MMoK97j3QWAxQJv6hcDkK-5lKTp1C6o5u0w9joIryZtmC9SUYIqycyQ3qiusXl0P1QMdrwIqwul4I1MI7EqumxE7ysIlFK~6NQLgWY5zWYHm1v6eP5A2uMLjlQ__&Key-Pair-Id=K16TX8JY5DS4IQ",
    "viewsCount": 3,
    "createdBy": "Arthur Wharton",
    "createdByUid": 15319,
    "users": [],
    "exportedVideoId": 26,
    "shareClub": 2675,
    "duration": 10,
    "createdByPicture": "https://local.youcoach.it/sites/default/files/sites/default/files/pictures/picture-15319-1731348216.png"
  }]
                  }/>


                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of videos.
                  </p>
                  
            </div>
        );
  }


} 