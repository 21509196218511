

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "va/like";

const serviceArguments = []

const serviceUrlArguments = []

const serviceSample = {
  "token": "e49bf1fb6001108a79db5748e5eadaf4a2c14ce6c8e9b7ea",
  "action": "like"
};


export function VAVideoLike() {
  return <VAVideoLikeComponent/>;
}


class VAVideoLikeComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Video Analysis - Like/Unlike a video</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    With this service, an user can:<br/>
                    - Like a video<br/>
                    - Unlike a video<br/>
                    - Remove a previous Like or Unlike<br/>
                  </p>

                  <p>
                    Action can be: <b>like</b> or <b>dislike</b>.<br/>
                    If the action is like (or dislike), and the user previously issued a like (or dislike), the like (or dislike) is unset for the user.<br/>
                    If the action is the opposite of what was previously issued the previous value is changed. I.e. if the user like the video and then dislike it, the previous like is changed to dislike.<br/>
                    The server always checks if likes are allowed for the video.<br/>
                    To know if an user liked or disliked a video, check the fields <b>likes</b> and <b>dislikes</b> in the video json. These fields contain an array of uids, which are the people that likes or disliked the video.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    POST request
                  </p>
                  <h4>Response</h4>
                      <p>
                        A JSON with the field "result". If result == ok, the service completed properly.
                      </p>
                      <CodeHighlighter code={
                        {
                          "result": "ok"
                        }
                      } />
                      <p>
                        Please note that if the user cannot access the video, a 401 error is return.
                      </p>
                  
            </div>
        );
  }


} 