import React from 'react';
import { Provider } from 'react-redux'

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import * as Pages from './pages';
import store from './store/index';
import logo from './youcoachlogo_white.svg';
import './App.css';


const pages = [
  { id: 'Introduction', content: Pages.Introduction },
  { id: 'separator' },
  { id: 'IOS Full Version', content: Pages.IOSFullVersion },
  { id: 'Latest Content', content: Pages.Articles },
  { id: 'Articles for Category', content: Pages.ArticlesList },
  { id: 'Articles for Column', content: Pages.ColumnArticlesList },
  { id: 'Articles Search', content: Pages.ArticlesSearch },
  { id: 'Article', content: Pages.Article },
  { id: 'eBook Details', content: Pages.EBookDetails },
  { id: 'eVideo Details', content: Pages.EVideoDetails },
  { id: 'separator' },
  { id: 'Add Device Token', content: Pages.UserAddDeviceToken, playerAccess: true },
  { id: 'Remove Device Token', content: Pages.UserRemoveDeviceToken, playerAccess: true },
  { id: 'Register', content: Pages.UserRegister, playerAccess: true },
  { id: 'Reset Password', content: Pages.UserResetPassword, playerAccess: true },
  { id: 'Login', content: Pages.Login, playerAccess: true },
  { id: 'Logout', content: Pages.Logout, playerAccess: true },
  { id: 'Refresh Token', content: Pages.RefreshToken, playerAccess: true },
  { id: 'Me', content: Pages.Me, playerAccess: true },
  { id: 'Update Profile', content: Pages.UserUpdate, playerAccess: true },
  { id: 'Items', content: Pages.Items },
  { id: 'separator' },
  { id: 'List favorites', content: Pages.ListFavorites },
  { id: 'List favorites (with details)', content: Pages.ListFavoritesDetails },
  { id: 'Add to favorites', content: Pages.AddToFavorites },
  { id: 'Remove from favorites', content: Pages.RemoveFromFavorites },
  { id: 'separator' },
  { id: 'Club', content: Pages.Club, playerAccess: false },
  { id: 'ClubImage', content: Pages.ClubImage, playerAccess: false },
  { id: 'Club Edit', content: Pages.ClubEdit, playerAccess: false },
  { id: 'separator' },
  { id: 'Seasons', content: Pages.Seasons, playerAccess: true },
  { id: 'Season Image', content: Pages.SeasonImage, playerAccess: true },
  { id: 'SeasonCreate', content: Pages.SeasonCreate },
  { id: 'SeasonEdit', content: Pages.SeasonEdit },
  { id: 'SeasonArchive', content: Pages.SeasonArchive },
  { id: 'Calendar', content: Pages.Calendar, playerAccess: true },
  { id: 'separator' },
  { id: 'Players', content: Pages.Players, playerAccess: true },
  { id: 'Player Details', content: Pages.PlayerDetails, playerAccess: true },
  { id: 'Player Stats', content: Pages.PlayerStats, playerAccess: true },
  { id: 'Player Stats Matches', content: Pages.PlayerStatsMatches, playerAccess: true },
  { id: 'Player Image', content: Pages.PlayerImage, playerAccess: true },
  { id: 'Player Create', content: Pages.PlayerCreate },
  { id: 'Player Edit', content: Pages.PlayerEdit, playerAccess: true },
  { id: 'Player Invite', content: Pages.PlayerInvite, playerAccess: false },
  { id: 'separator' },
  { id: 'Training Sessions', content: Pages.TrainingSessions, playerAccess: true },
  { id: 'Training Session Details', content: Pages.TrainingSessionDetails, playerAccess: true },
  { id: 'Training Session PDF', content: Pages.TrainingSessionPdf, playerAccess: true },
  { id: 'Training Session Create', content: Pages.TrainingSessionCreate },
  { id: 'Training Session Edit', content: Pages.TrainingSessionEdit },
  { id: 'Training Session Delete', content: Pages.TrainingSessionDelete },
  { id: 'Training Session Attendance', content: Pages.TrainingSessionAttendance },
  { id: 'Training Session Attendance Edit', content: Pages.TrainingSessionAttendanceEdit },
  { id: 'Training Session Notes', content: Pages.TrainingSessionNotes },
  { id: 'Training Session Create / Edit Note', content: Pages.TrainingSessionNoteEdit },
  { id: 'Training Session Delete  Note', content: Pages.TrainingSessionNoteDelete },
  { id: 'Training Session Drill Details', content: Pages.TrainingSessionDrillDetails, playerAccess: true },
  { id: 'Training Session Drill Image', content: Pages.TrainingSessionDrillDetailsImage, playerAccess: true },
  { id: 'separator' },
  { id: 'Matches', content: Pages.Matches, playerAccess: true },
  { id: 'Match Create', content: Pages.MatchCreate },
  { id: 'Match Edit', content: Pages.MatchEdit },
  { id: 'Match Delete', content: Pages.MatchDelete },
  { id: 'Match Details', content: Pages.MatchDetails, playerAccess: true },
  { id: 'Match Players', content: Pages.MatchPlayers, playerAccess: true },
  { id: 'Match Set Players', content: Pages.MatchSetPlayers },
  { id: 'Match Set Captain', content: Pages.MatchSetCaptain },
  { id: 'Match Set Vice Captain', content: Pages.MatchSetViceCaptain },
  { id: 'Match Set Opponent Players', content: Pages.MatchSetOpponentPlayers },
  { id: 'Match Opponent Player Create', content: Pages.MatchOpponentPlayerCreate },
  { id: 'Match Opponent Player Edit', content: Pages.MatchOpponentPlayerEdit },
  { id: 'Match Opponent Player Delete', content: Pages.MatchOpponentPlayerDelete },
  { id: 'Match System and System changes', content: Pages.MatchSystem },
  { id: 'Match Event Create', content: Pages.MatchEventCreate },
  { id: 'Match Event Edit', content: Pages.MatchEventEdit },
  { id: 'Match Event Delete', content: Pages.MatchEventDelete },
  { id: 'Match Evaluation', content: Pages.MatchEvaluation, playerAccess: true },
  { id: 'Match Evaluation Edit', content: Pages.MatchEvaluationEdit },
  { id: 'Match SetPlay Schema Image', content: Pages.MatchSetPlayImage },
  { id: 'separator' },
  { id: 'Generic Events', content: Pages.GenericEvents, playerAccess: true },
  { id: 'Generic Event Details', content: Pages.GenericEventDetails, playerAccess: true },
  { id: 'Generic Event Create', content: Pages.GenericEventCreate },
  { id: 'Generic Event Edit', content: Pages.GenericEventEdit },
  { id: 'Generic Event Delete', content: Pages.GenericEventDelete },
  { id: 'separator' },
  { id: 'Custom Drills', content: Pages.CustomDrills },
  { id: 'Custom Drill Details', content: Pages.CustomDrill },
  { id: 'Custom Drill Image', content: Pages.CustomDrillImage },
  { id: 'Custom Drill Sub Image', content: Pages.CustomDrillSubImage },
  { id: 'Generic Drill Details', content: Pages.GenericDrill },
  { id: 'Generic Drill Image', content: Pages.GenericDrillImage },
  { id: 'separator' },
  { id: 'TLM Event Data', content: Pages.TLMEventData, playerAccess: true },
  { id: 'TLM Event Data Edit', content: Pages.TLMEventDataEdit, playerAccess: true },
  { id: 'TLM Settings', content: Pages.TLMSettings, playerAccess: true },
  { id: 'TLM Settings Edit', content: Pages.TLMSettingsEdit },
  { id: 'TLM Hooper Data', content: Pages.TLMHooperData, playerAccess: true },
  { id: 'TLM Hooper Data Edit', content: Pages.TLMHooperDataEdit, playerAccess: true },
  { id: 'separator' },
  { id: 'Surveys Data', content: Pages.SurveysData, playerAccess: true },
  { id: 'Surveys Data Edit', content: Pages.SurveysDataEdit, playerAccess: true },
  { id: 'Surveys To Fill', content: Pages.SurveysToFill, playerAccess: true },
  { id: 'separator' },
  { id: 'Covid Event Data', content: Pages.CovidEventData, playerAccess: false },
  { id: 'Covid Event Data Edit', content: Pages.CovidEventDataEdit, playerAccess: false },
  { id: 'separator' },
  { id: 'Send Push Notification', content: Pages.SendPushNotification, playerAccess: false },
  { id: 'separator' },
  { id: 'Package Drills', content: Pages.PackageDrills, playerAccess: true },
  { id: 'separator' },
  { id: 'Fields', content: Pages.Fields, playerAccess: false },
  { id: 'separator' },
  { id: 'VA - List Videos', content: Pages.VAListVideos, playerAccess: true },
  { id: 'VA - Get Video', content: Pages.VAGetVideo, playerAccess: true },
  { id: 'VA - Add/Modify a comment', content: Pages.VAComment, playerAccess: true },
  { id: 'VA - Delete a comment', content: Pages.VADeleteComment, playerAccess: true },
  { id: 'VA - Increase Views count', content: Pages.VAVideoViewed, playerAccess: true },
  { id: 'VA - Like/Dislike', content: Pages.VAVideoLike, playerAccess: true },
];

class App extends React.Component {

  state = {
    page: 'Introduction',
    authInfo: null
  }

  render() {

    const currentPage = pages.find((val) => {
        return val.id === this.state.page;
    })

    return (
      <Provider store={store}>
        <div className="App">
          <Navbar className="header" bg="dark" expand="lg" variant="dark">
            <Navbar.Brand href="#home"><img
                alt=""
                src={logo}
                height="25"
                className="d-inline-block align-top"
              />{' '}
            REST API
            </Navbar.Brand>
          </Navbar>
          <div className="container-fluid">
            <div className="flex-md-nowrap row page-wrapper">
              <div className="col-xl-3 col-md-3 col-12 SidePanel d-flex flex-column">
                <div className="collapse Toc">
                  <div className="TocContent">
                    <Nav
                        activeKey={ currentPage.id }
                        onSelect={selectedKey => this.loadPage(selectedKey)}
                        className="flex-column"
                      >
                      { pages.map((item, index) => {

                          if (item.id === 'separator') {
                            return <hr key={index} className="menuDivider"/>;
                          }
                          return <Nav.Item key={item.id}>
                                  <Nav.Link className="sideNav" eventKey={ item.id }>{ item.id } { item.playerAccess ? 
                                    <span className="p">P</span> : null }</Nav.Link>
                                </Nav.Item>
                        })
                      }
                    </Nav>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-md-9 col-12 flex-column PagePanel">
                <div style={{ padding: '2rem 4rem', margin: 'auto' }}>
                { currentPage.content() }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Provider>
    );
  }

  loadPage = (page) => {
      this.setState({ page: page });
  }

}

export default App;
