

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "va/video/%token";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'token', type: 'string',  mandatory: true, default: '', description: 'Video Token'},
]

const serviceSample = {}


export function VAGetVideo() {
  return <VAGetVideoComponent/>;
}


class VAGetVideoComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Video Analysis - Get Video Info</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the full video data, including comments.
                  </p>
                  <p>
                    See VA-Comment and VA-Commente Delete to check how to add a comment.
                  </p>
                  <CodeHighlighter code={ {
  "id": 23,
  "name": "Demo_rinominato 222",
  "tags": null,
  "createdOn": "2024-11-13T23:10:06+00:00",
  "updatedOn": "2024-11-13T23:10:06+00:00",
  "updatedBy": "Arthur Wharton",
  "shareType": "club",
  "shareToken": "e49bf1fb6001108a79db5748e5eadaf4a2c14ce6c8e9b7ea",
  "shareSeason": 0,
  "description": null,
  "sharedVideoComments": [
    {
      "id": 19,
      "youcoachUid": 15319,
      "comment": "This is a commnet",
      "createdOn": "2024-11-19T11:43:43+00:00",
      "updatedOn": "2024-11-19T11:43:43+00:00",
      "createdByPicture": "https://local.youcoach.it/sites/default/files/sites/default/files/pictures/picture-15319-1731348216.png",
      "createdBy": "Arthur Wharton"
    },
    {
      "id": 20,
      "youcoachUid": 15319,
      "comment": "This is another comment",
      "createdOn": "2024-11-19T11:43:58+00:00",
      "updatedOn": "2024-11-19T11:43:58+00:00",
      "createdByPicture": "https://local.youcoach.it/sites/default/files/sites/default/files/pictures/picture-15319-1731348216.png",
      "createdBy": "Arthur Wharton"
    }
  ],
  "likeCount": 0,
  "dislikeCount": 0,
  "signedUrl": "https://cloudfront.youcoach.com/test/clubs/2675/presentation_29/export_280.mp4?Expires=4853603406&Signature=hq8cZQ06dXAJF9xCbOCrFFYaf7ci4hz-N6eW85nZE3zczfQxXJygrJplh7BxFk7bt~8jSFesFYr-lq1J~S-sKpXrhXeHPqDutgFB8YM2~egisjj47Ex2ttTyouoMWd13yywZmyW~FzI786fUFe1V4ZoG~kodwYvcf4~t9Qb~vffSAdWD-ULAxgGkMd6S9Dvk09ZHND-1qj11r~FrWm~Sgvu3oTLVvOqnF9VFxbg16TzPSMdt23m6UGnQJDIBwrSUGyWOJ6fAZlmbEzaWH1Gxp1yCbDaMJL5dv1NYLWS9q3ihsQedfqQR8tiOsBmAVIlMFu5wLfkit4P2Y8zNTg~Oew__&Key-Pair-Id=K16TX8JY5DS4IQ",
  "commentsCount": 2,
  "commentsEnabled": true,
  "likesEnabled": true,
  "coverTime": 0.391553,
  "thumbnailSignedUrl": "https://cloudfront.youcoach.com/test/clubs/2675/presentation_29/export_280_thumbnail.jpg?Expires=4853603408&Signature=MLTjbPprV5kR1ELr81WqzrO~mjZscW3lH0uxYgscVtkE9u5mkjCW1Q-VeccsXc8ts9ugAvgAygpm3pjK8byTiz~cUgDl~Q30t1lKgSKW0wo2x71g9UXn7sJ8g8BIRB6PMZHWLkuvT3snO939to6jz-ugz9VPScmEDB5CWFt77TFdaq6WGNtCAP~OyZGZjYg25RvrXyYJwtjSSrdOvvsuP1epVkYQkpKQXz5NXFyNf5FKSwsrPu47ubtNOXTgGHE7Pu4K-tWeIBdFlRBCbYGHAIhI-D0x~ccz-rd6gxdRPbXewvN1ZRb5HyBNQG6SpkJKlOrC341L-NeQEm7590Fmhw__&Key-Pair-Id=K16TX8JY5DS4IQ",
  "viewsCount": 5,
  "createdBy": "Arthur Wharton",
  "createdByUid": 15319,
  "users": [],
  "exportedVideoId": 98,
  "shareClub": 2675,
  "duration": 1,
  "likes": [],
  "dislikes": [],
  "createdByPicture": "<img typeof=\"foaf:Image\" src=\"https://local.youcoach.it/sites/default/files/styles/mycoach_user_picture_small/public/sites/default/files/pictures/picture-15319-1731348216.png?itok=myfYnvYZ\" width=\"60\" height=\"60\" alt=\"\" />"
}

                  }/>


                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the video.
                  </p>
                  
            </div>
        );
  }


} 