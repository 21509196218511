

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "va/viewed";

const serviceArguments = []

const serviceUrlArguments = []

const serviceSample = {
  "token": "e49bf1fb6001108a79db5748e5eadaf4a2c14ce6c8e9b7ea"
};


export function VAVideoViewed() {
  return <VAVideoViewedComponent/>;
}


class VAVideoViewedComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Video Analysis - Increase the viewed counter for a video</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    In order to track how many times a video is viewed, we provide a service to
                    increase the views count.<br/>
                    The service should be invoked when the user actually played the video. The best
                    moment to invoke the service is when at least 50% of the video has been played.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    POST request
                  </p>
                  <h4>Response</h4>
                      <p>
                        A JSON with the field "result". If result == ok, the service completed properly.
                      </p>
                      <CodeHighlighter code={
                        {
                          "result": "ok"
                        }
                      } />
                      <p>
                        Please note that if the user cannot access the video, a 401 error is return.
                      </p>
                  
            </div>
        );
  }


} 